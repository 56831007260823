<template>
<section class="page-bank-info">
  <div class="container p-t-15" v-if="!bankPerformance || !loansBankComparison || !bankMandateList">
    <spinner />
  </div>
  <template v-else>
    <header class="bank-header m-b-20">
      <div class="container">
        <div class="logo-wrapper">
          <div class="logo-holder">
            <bank-logo class="logo" :bank-name="bankPerformance.bankName" :alt="bankPerformance.fullBankName" />
          </div>
        </div>
        <div class="header-info m-l-20">
          <h2 class="h1 m-b-10">{{ bankPerformance.fullBankName }}</h2>
        </div>
      </div>
    </header>
    <section class="container" v-if="bankPerformance.bca">
      <div class="bank-details-wrapper card p-b-30 m-b-20">
        <h2 class="title m-b-25">{{ bankPerformance.bca.name }}</h2>
        <div class="bank-details">
          <div class="detail" v-if="bankPerformance.bca.serviceFee && bankPerformance.bca.serviceFee.amount !== null">
            <template v-if="bankPerformance.bca.serviceFee.amount === null">N/A</template>
            <template v-else>
              <div class="detail-title">A/c Fee</div>
              <div class="detail-amount"><currency-symbol />{{ bankPerformance.bca.serviceFee.amount | commarize }}</div>
              <div class="detail-amount-additional">{{ bankPerformance.bca.serviceFee.frequency }}</div>
            </template>
          </div>
          <div class="detail" v-if="bankPerformance.bca.overdraft">
            <div class="detail-title">Overdraft Limit</div>
            <div class="detail-amount">
              <template v-if="bankPerformance.bca.overdraft.valueMax === null">N/A</template>
              <template v-else><currency-symbol />{{ bankPerformance.bca.overdraft.valueMax | commarize }}</template>
            </div>
          </div>
          <div class="detail" v-if="bankPerformance.bca.overdraft">
            <div class="detail-title">Overdraft EAR</div>
            <div class="detail-amount">
              <template v-if="bankPerformance.bca.overdraft.earMin === null">N/A</template>
              <template v-else>{{ bankPerformance.bca.overdraft.earMin }}%</template>
            </div>
          </div>
          <div class="detail" v-if="bankPerformance.bca.features && bankPerformance.bca.features.isInternationalPaymentsSupported !== null">
            <div class="detail-title">International Payments</div>
            <div class="detail-amount">
              <icon
                :class="bankPerformance.bca.features.isInternationalPaymentsSupported ? 'text-primary' : 'text-danger'"
                :name="bankPerformance.bca.features.isInternationalPaymentsSupported ? 'check' : 'times'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bank-features-and-fees row m-b-15">
        <div class="col-sm-12 col-md-6 m-b-15" v-if="bankPerformance.bca.features.accountManagementFeatures">
          <section class="card">
            <h2 class="h4">Account Features</h2>
            <ul v-if="bankPerformance.bca.features.accountManagementFeatures.length">
              <li v-for="(feature, index) in bankPerformance.bca.features.accountManagementFeatures" :key="index">{{ feature }}</li>
            </ul>
            <div v-else>None</div>
          </section>
        </div>
        <div class="col-sm-12 col-md-6 m-b-15" v-if="bankPerformance.bca.fees">
          <section class="card">
            <h2 class="h4">Account Fees</h2>
            <ul v-if="bankPerformance.bca.fees.length">
              <li v-for="(fee, index) in bankPerformance.bca.fees" :key="index">
                <b v-if="fee.type">{{ fee.typeDescription }}</b>:
                <template v-if="!fee.unitOfMeasure"><currency-symbol /></template>
                <template>{{ fee.amountMin | commarize }}</template>
                <template v-if="fee.unitOfMeasure">{{ fee.unitOfMeasure }}</template>
                <template v-if="fee.frequencyDescription"><br>{{ fee.frequencyDescription }}</template>
              </li>
            </ul>
            <div v-else>None</div>
          </section>
        </div>
        <div class="col-sm-12 col-md-6 m-b-15">
          <section class="card">
            <h2 class="h4">Unsecured Loan Facility</h2>
            <template v-if="loansBankComparison.bankName">
              <ul>
                <li><b>MIN Amount</b>: <currency-symbol />{{ loansBankComparison.amountMin | commarize }}</li>
                <li><b>MAX Amount</b>: <currency-symbol />{{ loansBankComparison.amountMax | commarize }}</li>
                <li><b>APR</b>: {{ loansBankComparison.apr }}%</li>
                <li><b>MIN Loan Term</b>: {{ loanTermMaxDic[loansBankComparison.loanTermMin] || '' }}</li>
                <li><b>MAX Loan Term</b>: {{ loanTermMaxDic[loansBankComparison.loanTermMax] || '' }}</li>
                <li>
                  <b>Early Repayment Fee</b>:
                  <icon
                    :class="loansBankComparison.hasEarlyRepaymentFee ? 'text-primary' : 'text-danger'"
                    :name="loansBankComparison.hasEarlyRepaymentFee ? 'check' : 'times'"
                  />
                </li>
                <li>
                  <b>Arrangement Fee</b>:
                  <icon
                    :class="loansBankComparison.hasArrangementFee ? 'text-primary' : 'text-danger'"
                    :name="loansBankComparison.hasArrangementFee ? 'check' : 'times'"
                  />
                </li>
              </ul>
              <div class="p-t-15">
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'matches-deal', params: { opportunityId: loansBankComparison.opportunityId } }"
                >See More Info</router-link>
              </div>
            </template>
            <div v-else>None</div>
          </section>
        </div>
        <div class="col-sm-12 col-md-6 m-b-15" v-if="bankPerformance.bca.overdraft && bankPerformance.bca.overdraft.fees">
          <section class="card">
            <h2 class="h4">Overdraft Fees</h2>
            <ul v-if="bankPerformance.bca.overdraft.fees.length">
              <li v-for="(fee, index) in bankPerformance.bca.overdraft.fees" :key="index">
                <b v-if="fee.feeTypeCode">{{ fee.feeTypeCodeDescription }}</b>:
                <template v-if="!fee.unitOfMeasure"><currency-symbol /></template>
                <template>{{ fee.amountMin | commarize }}</template>
                <template v-if="fee.unitOfMeasure">{{ fee.unitOfMeasure }}</template>
                <template v-if="fee.frequency"><br>{{ fee.frequencyDescription }}</template>
              </li>
            </ul>
            <div v-else>None</div>
          </section>
        </div>
      </div>
    </section>
    <footer class="container">
      <div class="m-b-15">
        <a href @click.prevent="sendCompareClickToAmplitude" class="btn btn-primary">Compare to other banks</a>
      </div>
      <div v-if="bankMandateList && bankMandateList.length">
        <StartSwitchRevolut v-if="bankName === 'revolutBankFreelancer' || bankName === 'revolutBankProfessional' || bankName === 'revolutBankStandard' || bankName === 'revolutBankStart'"/>
        <a href @click.prevent="sendStartSwitchClickToAmplitude" class="btn btn-primary" v-else>Start Switch</a>
      </div>
    </footer>
  </template>
</section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { bankMandateListRoutine, bankGetters } from '@/store/modules/bank/routines'
import BankLogo from '@/components/BankLogo'
import StartSwitchRevolut from '@/components/integrations/StartSwitchRevolut'
import { getSingleBankComparison, getSingleLoansBankComparison } from '@/api/openBankingOpenData'
import { loanTermMaxDic, amplitudeEvents } from '@/utils/constants'

export default {
  name: 'BankInfo',
  components: {
    BankLogo,
    StartSwitchRevolut
  },
  data () {
    return {
      bankPerformance: null,
      loansBankComparison: null,
      loanTermMaxDic
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      companyCurrent: companyGetters.COMPANY_CURRENT,
      bankMandateList: bankGetters.BANK_MANDATE_LIST
    }),
    bankName () {
      return this.$route.params.bankName
    }
  },
  methods: {
    ...mapActions({
      getBankMandateList: bankMandateListRoutine.TRIGGER
    }),
    fillBankPerformance () {
      return getSingleBankComparison(this.bankName)
        .then(res => {
          const { bcas, bankName, fullBankName, logoUri } = res.data.bankComparisonModel
          const bca = bcas.find(bca => bca.isRepresentative) || bcas[0] || null
          const { overdrafts, ...bcaClean } = bca
          const overdraft = bca ? bca.overdrafts.find(overdraft => overdraft.isRepresentative) || bca.overdrafts[0] || null : null
          this.bankPerformance = {
            bankName,
            fullBankName,
            logoUri,
            bca: {
              ...bcaClean,
              overdraft
            }
          }
        })
        .catch(e => {
          this.bankPerformance = {}
        })
    },
    fillLoansBankPerformance () {
      return getSingleLoansBankComparison(this.bankName)
        .then(res => {
          this.loansBankComparison = res.data || {}
        })
        .catch(e => {
          this.loansBankComparison = {}
        })
    },
    sendStartSwitchClickToAmplitude () {
      /** Amplitude Analytics */
      this.$ma.setUserProperties({
        'Bank Switch Started': this.bankName
      })
      this.$ma.trackEvent({
        eventType: amplitudeEvents.savingCheckout.DETAIL_SWITCH_CLICKED,
        eventProperties: {
          'Bank Account Product': this.bankName,
          'A/C Fee': this.bankPerformance.bca.serviceFee && this.bankPerformance.bca.serviceFee.amount !== null ? `${this.bankPerformance.bca.serviceFee.amount} ${this.bankPerformance.bca.serviceFee.frequency}` : 'N/A',
          'Overdraft EAR': this.bankPerformance.bca.overdraft.earMin,
          'Max Overdraft Limit': this.bankPerformance.bca.overdraft.valueMax,
          'International payments support': this.bankPerformance.bca.features.isInternationalPaymentsSupported
        }
      })
      this.$ma.trackView({ viewName: 'SWITCH' })
      this.$router.push({ name: 'banks-switch-account', params: { bankName: this.bankName } })
    },
    sendCompareClickToAmplitude () {
      /** Amplitude Analytics */
      this.$ma.addItem({
        'Compare to other banks clicked': 1
      })
      this.$ma.trackEvent({
        eventType: amplitudeEvents.savingCheckout.COMPARE_CLICKED,
        eventProperties: {
          'Bank Account Product': this.bankName
        }
      })
      this.$ma.trackView({ viewName: 'PERFORMANCE_DETAILS' })
      this.$router.push({ name: 'banks-performance-details' })
    }
  },
  mounted () {
    this.fillBankPerformance()
    this.fillLoansBankPerformance()
    this.getBankMandateList(this.companyId)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.page-bank-info {
  width: 100%;
  padding-top: 0;
  flex-grow: 1;
}
.bank-header {
  $logo-size: 126px;
  color: #fff;
  background-color: var(--color-primary-400);
  padding: 17px 0;
  @media only screen and (max-width: $breakpoint-sm-max) {
    $logo-size: 80px;
  }
  .container {
    display: flex;
    align-items: center;
    .logo-wrapper {
      flex-shrink: 0;
      flex-grow: 0;
    }
    .logo-holder {
      display: table-cell;
      vertical-align: middle;
      max-width: $logo-size;
      width: $logo-size;
      height: $logo-size;
      border-radius: 50%;
      background-color: #fff;
      margin: 0;
      overflow: hidden;
      .logo {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .header-info {
      word-break: break-word;
    }
  }
}
.bank-details {
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: -15px;
  align-items: baseline;
  @media only screen and (max-width: $breakpoint-md-max) {
    justify-content: space-between;
  }
  @media only screen and (max-width: $breakpoint-sm-max) {
    justify-content: space-around;
    margin-bottom: -25px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .detail {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 15px;
    text-align: center;
    @media only screen and (max-width: $breakpoint-sm-max) {
      flex-basis: calc(50% - 2 * 32px);
      margin-bottom: 25px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .detail-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $color-secondary;
    }
    .detail-amount {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
    }
    .detail-amount-additional {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }
}
.bank-features-and-fees {
  ul {
    margin: 0;
    padding-left: 20px;
  }
  .card {
    height: 100%;
  }
}
</style>
